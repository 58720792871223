import { updateLoader } from "action/DashboardInformation";
import { NormalButton } from "component/common/NormalButton";
import OtpPage from "component/common/OtpScreen/otpPage";
import { useFormik } from "formik";
import { externalLinks } from "helpers/constant";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import arabic from "react-date-object/calendars/arabic";
import gregorian from "react-date-object/calendars/gregorian";
import arabic_en from "react-date-object/locales/arabic_en";
import gregorian_en from "react-date-object/locales/gregorian_en";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { store } from "service/helpers";
import instance from "service/instance";
import * as globalUtils from "../../../../../helpers/utils";
import "./CommonIndividualsForm.scss";
import { Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import { NormalSelect } from "component/common/NormalSelect";
import selectArrow from "assets/svg/selectArrowIconHome.svg";
import moment from "moment";

const CommonIndividualsForm = (props) => {
  const { i18n, t } = useTranslation();
  const [yearsSelectList, setYearsSelectList] = useState();
  const [showOTP, setShowOTP] = useState(null);
  const [token, setToken] = useState("");
  const toast = useRef(null);
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
  const dispatch = useDispatch();
  const CryptoJS = require("crypto-js");
  const [calenderType, setCalenderType] = useState(gregorian);
  const [calenderLang, setCalenderLang] = useState(gregorian_en);
  const [finalDOB, setFinalDob] = useState("");
  const [months, setMonths] = useState([
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
  ]);

  const handleDateofBirth = (e) => {
    setFinalDob(e);
  };

  const getDays = () => {
    const arr = [];
    for (let index = 1; index <= 31; index++) {
      arr.push({
        label: index.toString().padStart(2, "0"),
        value: index.toString().padStart(2, "0"),
      });
    }
    return arr;
  };

  const [days, setDays] = useState(getDays());
  let today = new Date();
  let day = today.getDate();
  let nextDay = day === 0 ? 0 : day;
  let maxDate = new Date();
  maxDate.setDate(nextDay);

  const minDateExp = new Date();
  minDateExp.setDate(minDateExp.getDate() - 7);

  const maxDateExp = new Date();
  maxDateExp.setDate(maxDateExp.getDate() + 7);

  const getDateListYears = (currentYear) => {
    return Array(100)
      .fill()
      .map((ele, i) => {
        const value = i + currentYear - 99;
        return {
          key: value,
          label: "" + value,
          value: "" + value,
        };
      })
      .sort((a, b) => b.key - a.key);
  };
  const getHijriYearOfBirthList = () => {
    const currentYear = new Date().getFullYear();
    const hijriYear = Math.round((currentYear - 623) * (33 / 32));
    return getDateListYears(hijriYear);
  };
  const getGregYearOfBirthList = () => {
    const gregYear = new Date().getFullYear();
    return getDateListYears(gregYear);
  };

  const isValidSaudiIDOrIQama = (idNumber) => {
    return /^[1,2]{1}[0-9]{10}$/i.test(idNumber);
  };

  const isValidSaudiPhoneNumber = (value) => {
    return /^(5)(5|0|3|6|4|9|1|8|7)([0-9]{8})$/.test(value);
  };
  const isValidCRNumber = (idNumber) => {
    return /^[0-9]{11}$/i.test(idNumber);
  };

  function encrypt(value) {
    const idx = process.env.REACT_APP_EXTERNAL_IDX;
    const message = CryptoJS.AES.encrypt(value.toString(), idx);
    return message.toString();
  }
  const trackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked);
  };

  const formik = useFormik({
    initialValues: {
      crNumber: "",
      idNumber: "",
      email: "",
      mobile: "",
      yearOfBirth: "",
      expireDate: null,
      passportNumber: "",
      visaNumber: "",
      monthOfBirth: "",
      expiryDate: "",
      dayOfBirth: "",
      hjri: false,
      mobileNumber: "",
    },
    validate: (data) => {
      let errors = {};
      if (!props.isRedirect) {
        // debugger
        // travel
        if (props.application == "TRAVEL" && !data.idNumber) {
          errors.idNumber = t("idRequired");
        } else if (
          props.application == "TRAVEL" &&
          isValidSaudiIDOrIQama(data.idNumber)
        ) {
          errors.idNumber = t("idNotValid");
        }
        if (props.application == "TRAVEL" && !data.monthOfBirth) {
          errors.monthOfBirth = t("monthRequired");
        }
        if (props.application == "TRAVEL" && !data.yearOfBirth) {
          errors.yearOfBirth = t("yobRequired");
        }
        if (props.application == "TRAVEL" && !data.mobile) {
          errors.mobile = t("mobRequired");
        } else if (
          props.application == "TRAVEL" &&
          isValidSaudiPhoneNumber(data.mobile)
        ) {
          errors.mobile = t("mobNotValid");
        }
        // travel ends
        // HOME
        if (props.application == "HOME" && !data.idNumber) {
          errors.idNumber = t("idRequired");
        } else if (
          props.application == "HOME" &&
          isValidSaudiIDOrIQama(data.idNumber)
        ) {
          errors.idNumber = t("idNotValid");
        }
        if (props.application == "HOME" && !data.monthOfBirth) {
          errors.monthOfBirth = t("monthRequired");
        }
        if (props.application == "HOME" && !data.yearOfBirth) {
          errors.yearOfBirth = t("yobRequired");
        }
        if (props.application == "HOME" && !data.mobile) {
          errors.mobile = t("mobRequired");
        } else if (
          props.application == "HOME" &&
          isValidSaudiPhoneNumber(data.mobile)
        ) {
          errors.mobile = t("mobNotValid");
        }
        // HOME ends

        if (props.application == "PARENT" && !data.idNumber) {
          errors.idNumber = t("idRequired");
        } else if (
          props.application == "PARENT" &&
          isValidSaudiIDOrIQama(data.idNumber)
        ) {
          errors.idNumber = t("idNotValid");
        }
        if (props.application == "PARENT" && !data.monthOfBirth) {
          errors.monthOfBirth = t("monthRequired");
        }
        if (props.application == "PARENT" && !data.yearOfBirth) {
          errors.yearOfBirth = t("yobRequired");
        }
        if (props.application == "PARENT" && !data.mobile) {
          errors.mobile = t("mobRequired");
        } else if (
          props.application == "PARENT" &&
          isValidSaudiPhoneNumber(data.mobile)
        ) {
          errors.mobile = t("mobNotValid");
        }

        if (
          !props.sme &&
          !props.isVisitVisa &&
          !data.idNumber &&
          props.application === "Medmal"
        ) {
          errors.idNumber = t("idRequired");
        } else if (!props.sme && isValidSaudiIDOrIQama(data.idNumber)) {
          errors.idNumber = t("idNotValid");
        }
        if (props.sme && !data.crNumber) {
          errors.crNumber = "CR Number is required.";
        } else if (props.sme && isValidCRNumber(data.crNumber)) {
          errors.crNumber = "CR Number is not valid";
        }
        if (!props.isVisitVisa && !data.mobile) {
          errors.mobile = t("mobRequired");
        } else if (isValidSaudiPhoneNumber(data.mobile)) {
          errors.mobile = t("mobNotValid");
        }
        if (props.sme && !data.expireDate) {
          errors.expireDate = "Expire Date is Required";
        }
        if (
          !props.sme &&
          props.application === "Medmal" &&
          // !props.isVisitVisa &&
          !data.yearOfBirth
        ) {
          errors.yearOfBirth = t("yobRequired");
        }
        if (
          !props.sme &&
          props.application === "Medmal" &&
          // !props.isVisitVisa &&
          !data.monthOfBirth
        ) {
          errors.monthOfBirth = t("monthRequired");
        }
        if (
          props.isVisitVisa &&
          !data.visaNumber &&
          props.application !== "TRAVEL" &&
          props.application !== "HOME" &&
          props.application !== "PARENT"
        ) {
          errors.visaNumber = t("visaNotValid");
        }
        if (props.isVisitVisa && !data.yearOfBirth) {
          errors.yearOfBirth = t("yobRequired");
        }
        if (props.isVisitVisa && !data.monthOfBirth) {
          errors.monthOfBirth = t("monthRequired");
        }
        if (props.isVisitVisa && !data.expiryDate) {
          errors.expiryDate = t("expiryDateError");
        }
        if (props.application === "motor" && !data.dayOfBirth && data.hjri) {
          errors.dayOfBirth = t("dayOfBirth");
        }
      }

      return errors;
    },
    onSubmit: (data) => {
      if (!props.isRedirect && props.isVisitVisa) {
        window.lmSMTObj.track("buyNowClicked", {
          "Product Name": props.application,
          // "Visa Number": data?.visaNumber,
          // "Passport Number": data?.passportNumber,
        });

        const formattedDate = moment(data.expiryDate).format('YYYY-MM-DD');
        let url =
          externalLinks.baseurl +
          "visit-visa?visaNumber=" +
          encrypt(data?.visaNumber) +
          "&yob=" +
          encrypt(data.yearOfBirth) +
          "&mob=" +
          encrypt(data.monthOfBirth) +
          "&dob=" +
          encrypt(data.dayOfBirth) +
          "&visaExpiryDate=" +
          encrypt(formattedDate) +
          "&hijri=" +
          data.hjri;
        return window.location.assign(url);
      }
      if (props.application === "MOTOR" && !props.sme) {
        window.lmSMTObj.track("buyNowClicked", {
          "Product Name": props.application,
          // "National Id": data.idNumber,
          // "Year of Birth": data.yearOfBirth,
          // "Month Of Birth": data.monthOfBirth,
          // Mobile: data.mobile,
        });
        redirectMotorPage(data);
        return;
      }
      if (props.application === "Medmal") {
        window.lmSMTObj.track("buyNowClicked", {
          "Product Name": props.application,
          // "National Id": data.idNumber,
          // "Year of Birth": data.yearOfBirth,
          // "Month Of Birth": data.monthOfBirth,
          // Mobile: data.mobile,
        });
        let url =
          externalLinks.medmal_v3 +
          "?lang=" +
          i18n.language +
          "&id=" +
          encrypt(data.idNumber) +
          "&mobile=" +
          encrypt(data.mobile) +
          "&yob=" +
          encrypt(data.yearOfBirth) +
          "&mob=" +
          encrypt(data.monthOfBirth);
        window.location.assign(url);
      }
      if (props.isRedirect && props.isMedmal) {
        window.lmSMTObj.track("MedMalBuyNowClicked", {
          // "Passport Number": data?.passportNumber,
          // Mobile: data?.mobileNumber,
        });
        let url =
        externalLinks.medmal_v3 +
        "?lang=" + i18n.language +
          "&id=" +
          encrypt(data?.passportNumber) +
          "&mobile=" +
          encrypt(data?.mobileNumber);
        window.location.assign(url);
      }
      if (props.application === "TRAVEL" && !props.sme) {
        debugger
        window.lmSMTObj.track("buyNowClicked", {
          "Product Name": props.application,
          // "National Id": data.idNumber,
          // "Year of Birth": data.yearOfBirth,
          // "Month Of Birth": data.monthOfBirth,
          // Mobile: data.mobile,
        });
        let url =
        externalLinks.travelInsurance_v3 +
        "?lang=" +
        i18n.language +
          "&id=" +
          encrypt(data?.idNumber) +
          "&mobile=" +
          encrypt(data?.mobile) +
          "&year=" +
          encrypt(data?.yearOfBirth) +
          "&month=" +
          encrypt(data?.monthOfBirth)
        return window.location.assign(url);
      }
      if (props.application === "HOME" && !props.sme) {
        window.lmSMTObj.track("buyNowClicked", {
          "Product Name": props.application,
          // mobileNumber: data.mobile,
          // "National Id": data.idNumber,
          // yearOfBirth: data.yearOfBirth,
          // monthOfBirth: data.monthOfBirth,
        });
        // console.log(data, "data====>>>>");
        let url =
          externalLinks.homeInsurance_v3 +
          "?lang=" +
          i18n.language +
          "&id=" +
          encrypt(data?.idNumber) +
          "&mobile=" +
          encrypt(data?.mobile) +
          "&yob=" +
          encrypt(data?.yearOfBirth) +
          "&mob=" +
          encrypt(data.monthOfBirth);
        window.location.href = url;
      }
      if (props.application === "domestic-worker" && !props.sme) {
        let url =
          externalLinks.DomesticWorkerHealth +
          "?" +
          "lang=" +
          i18n.language +
          "&id=" +
          encrypt(data?.idNumber) +
          "&mobile=" +
          encrypt(data?.mobile) +
          "&yob=" +
          encrypt(data?.yearOfBirth) +
          "&mob=" +
          encrypt(data.monthOfBirth);
        window.location.href = url;
      }
      if (props.application === "PARENT" && !props.sme) {
        // console.log(data, "data====>>>>");
        let url =
          externalLinks.ParentHealth +
          "?" +
          "lang=" +
          i18n.language +
          "&id=" +
          encrypt(data?.idNumber) +
          "&mobile=" +
          encrypt(data?.mobile) +
          "&yob=" +
          encrypt(data?.yearOfBirth) +
          "&mob=" +
          encrypt(data.monthOfBirth);
        window.location.href = url;
      }
      if (props.url.includes("http")) {
        window.lmSMTObj.track(props.application + "Clicked");
        // window.open(props.url);
      } else {
        window.lmSMTObj.track(props.application + "Clicked");
        // history.push(props.url);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("yearOfBirth", "");
    formik.setFieldValue("monthOfBirth", "");
    formik.setFieldValue("dayOfBirth", "");
  }, [formik.values.hjri]);

  const redirectMotorPage = (data) => {
    let url =
      externalLinks.common +
      "motor?" +
      "lang=" +
      i18n.language +
      "&id=" +
      encrypt(data.idNumber) +
      "&mobile=" +
      encrypt(data.mobile) +
      "&yob=" +
      encrypt(data.yearOfBirth) +
      "&mob=" +
      encrypt(data.monthOfBirth) +
      "&dob=" +
      encrypt(data.dayOfBirth) +
      "&hijri=" +
      data.hjri;
    window.location.assign(url);
  };

  // check if the customer is saudi Or Not
  let isSaudi = formik?.values?.idNumber?.charAt(0) === "1";

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
    if (formik?.values?.hjri) {
      const years = getHijriYearOfBirthList();
      //    setYearsSelectList(years);
      // const years = getGregYearOfBirthList();
      setYearsSelectList(years);
    } else {
      const years = getGregYearOfBirthList();
      setYearsSelectList(years);
    }
  }, [formik.values.hjri]);

  const sendOTPHandler = (data) => {
    getCustomerDetails(data).then((response) => {
      const temp = response?.verificationData;
      if (response?.resultCode === "S") {
        setToken(temp);
        setShowOTP(true);
      } else if (response?.resultCode === "V") {
        toast.current.show({
          severity: "error",
          summary: t("Error"),
          detail: response?.resultMessage,
          life: 3000,
          closable: false,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: t("Error"),
          detail: t("InfoError"),
          life: 3000,
          closable: false,
        });
      }
    });
  };

  const getCustomerDetails = async (data) => {
    dispatch(updateLoader(true));
    try {
      const response = await instance.post(
        "/motorRetail/getCustomerDetails",
        {
          applicationType: "TAWN",
          consumerTrackingID: "75678",
          idNumber: `${data.idNumber}`,
          languageCode: i18n.language.slice(0, 1).toUpperCase(),
          requestGeneratedTime: null,
          yearOfBirth: data.yearOfBirth,
          email: "test@gmail.com",
          mobileNumber: "+966" + data.mobile,
          channelDetails: {
            applicationType: "TAWN",
            applicationCategory: "RH",
            userName: "EBUSUSER",
            sourceCode: "550541",
            channelCode: "RH",
            branchCode: "85",
          },
        },
        {
          headers: {
            Authorization:
              "Bearer" +
              " " +
              store.getState().loginDetailsReducer?.loginResponse?.user_info
                ?.token,
          },
        }
      );
      dispatch(updateLoader(false));
      return response.data;
    } catch (err) {
      dispatch(updateLoader(false));
      toast.current.show({
        severity: "error",
        summary: t("Error"),
        detail: t("InfoError"),
        life: 3000,
        closable: false,
      });
      console.error(err);
    }
  };

  const verifyOTPHandler = (otp) => {
    verifyOTP("+966" + formik.values.mobile, otp).then((response) => {
      console.log(response);
      if (response?.data?.status === "1") {
        setShowOTP(false);
        if (props.application === "MOTOR") {
          let url =
            externalLinks.common +
            "motor?id=" +
            encrypt(formik.values.idNumber) +
            "&mobile=" +
            encrypt(formik.values.mobile) +
            "&yob=" +
            encrypt(formik.values.yearOfBirth);
          window.open(url);
        } else {
          window.location.assign(props.url + token);
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: t("Error"),
          detail: t("OtpError"),
          life: 3000,
          closable: false,
        });
        console.error(response);
      }
    });
  };
  const verifyOTP = async (mobileNumber, otpCode) => {
    dispatch(updateLoader(true));
    try {
      const response = await instance.post(
        "/common/validateOTPLanding",
        verifyOTPRequest(mobileNumber, otpCode),
        {
          headers: {
            Authorization:
              "Bearer" +
              " " +
              store.getState().loginDetailsReducer?.loginResponse?.user_info
                ?.token,
          },
        }
      );
      dispatch(updateLoader(false));
      return response;
    } catch (err) {
      dispatch(updateLoader(false));
      console.log("Error : ", err);
    }
  };
  const verifyOTPRequest = (mobileNumber, otp) => {
    return {
      mobile: mobileNumber,
      channel: "Portal",
      otpCode: otp,
      language: i18n.language === "ar" ? "1" : "2",
    };
  };

let appbase = process.env.REACT_APP_REDIRECT_URL;

  const handleRenewClick = () => {
    if (props.application === "MOTOR") {
      window.location.assign(
        externalLinks.Renewal + "renewal?lang=" + i18n.language
      );
    } else if (props.application === "TRAVEL") {
      window.location.assign(`${appbase}/travel-insurance/?renwal=true`)
    } else {
      window.location.assign(externalLinks.common + "medmal?renewal=true");
    }
  };

  const [motorAnalytics, setMotorAnalytics] = useState(false);

  useEffect(() => {
    if (
      formik.values.idNumber.length === 0 &&
      formik.values.mobile.length === 0 &&
      formik.values.yearOfBirth.length === 0
    ) {
      setMotorAnalytics(false);
    }
    if (
      props.application === "MOTOR" &&
      !motorAnalytics &&
      (formik.values.idNumber.length > 0 ||
        formik.values.mobile.length > 0 ||
        formik.values.yearOfBirth.length > 0)
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "select_item",
        ecommerce: {
          funnel_step: "step_1",
          step_name: "motor_policy_new_select_item",
          items: [
            {
              item_name: "Motor Insurance New",
              item_category: "Motor Insurance",
              item_class: "Individual",
            },
          ],
        },
      });
      setMotorAnalytics(true);
    }
  }, [formik.values.idNumber, formik.values.mobile, formik.values.yearOfBirth]);

  useEffect(() => {
    if (
      globalUtils.isSaudi(formik.values.idNumber) &&
      props.application !== "TRAVEL" &&
      props.application !== "HOME" &&
      props.application !== "PARENT"
    ) {
      setCalenderType(arabic);
      setCalenderLang(arabic_en);
    } else {
      setCalenderType(gregorian);
      setCalenderLang(gregorian_en);
    }
  }, [formik.values.idNumber]);

  const [homeAnalytics, setHomeAnalytics] = useState(false);

  useEffect(() => {
    if (
      formik.values.idNumber.length === 0 &&
      formik.values.mobile.length === 0 &&
      formik.values.yearOfBirth.length === 0
    ) {
      setHomeAnalytics(false);
    }
    if (
      !homeAnalytics &&
      (formik.values.idNumber.length > 0 ||
        formik.values.yearOfBirth.length > 0 ||
        formik.values.monthOfBirth.length > 0 ||
        formik.values.mobile.length > 0)
    ) {
      if (props.application === "HOME") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "select_item",
          ecommerce: {
            funnel_step: "step_1",
            step_name: "home_insurance_select_item",
            items: [
              {
                item_name: "Home Insurance",
                item_category: "PNC Insurance",
                item_class: "Individual",
              },
            ],
          },
        });
      } else if (props.application === "Medmal") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "select_item",
          ecommerce: {
            funnel_step: "step_1",
            step_name: "mmp_new_select_item",
            items: [
              {
                item_name: "MMP New",
                item_category: "PNC Insurance",
                item_class: "Individual",
              },
            ],
          },
        });
      }
      setHomeAnalytics(true);
    }
  }, [
    formik.values.idNumber,
    formik.values.yearOfBirth,
    formik.values.yearOfBirth,
    formik.values.mobile,
  ]);

  const [visitVisaAnalytics, setVisitVisaAnalytics] = useState(false);

  // useEffect(() => {
  //   if (
  //     formik.values.visaNumber.length === 0 &&
  //     formik.values.expiryDate.length === 0
  //   ) {
  //     setVisitVisaAnalytics(false);
  //   }
  //   if (
  //     props.isVisitVisa &&
  //     !visitVisaAnalytics &&
  //     (formik.values.visaNumber.length > 0 ||
  //       formik.values.expiryDate.length > 0)
  //   ) {
  //     window.dataLayer = window.dataLayer || [];
  //     window.dataLayer.push({
  //       event: "select_item",
  //       ecommerce: {
  //         funnel_step: "step_1",
  //         step_name: "visit_visa_extension_select_item",
  //         items: [
  //           {
  //             item_name: "Visit Visa Extension",
  //             item_category: "Medical Insurance",
  //             item_category1: "New",
  //             item_class: "Individual",
  //           },
  //         ],
  //       },
  //     });
  //     setVisitVisaAnalytics(true);
  //   }
  // }, [formik.values.visaNumber, formik.values.expiryDate]);

  const [travelAnalytics, setTravelAnalytics] = useState(false);

  useEffect(() => {
    if (
      formik.values.idNumber.length === 0 &&
      formik.values.yearOfBirth.length === 0 &&
      formik.values.monthOfBirth.length === 0 &&
      formik.values.mobile.length === 0
    ) {
      setTravelAnalytics(false);
    }
    if (
      props.application === "TRAVEL" &&
      !travelAnalytics &&
      (formik.values.idNumber.length > 0 ||
        formik.values.yearOfBirth.length > 0 ||
        formik.values.monthOfBirth.length > 0 ||
        formik.values.mobile.length > 0)
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "select_item",
        ecommerce: {
          funnel_step: "step_1",
          step_name: "travel_select_item",
          items: [
            {
              item_name: "Travel",
              item_category: "PNC Insurance",
              item_class: "Individual",
            },
          ],
        },
      });
      setTravelAnalytics(true);
    }
  }, [
    formik.values.idNumber,
    formik.values.yearOfBirth,
    formik.values.monthOfBirth,
    formik.values.mobile,
  ]);

  return (
    <>
      <Toast ref={toast} />
      <div className="form_wrapper">
        <form onSubmit={formik.handleSubmit} className="p-fluid mt-4">
          {!props.sme && !props.medmal && !props.isVisitVisa && (
            <>
              {props.application !== "TRAVEL" &&
                props.application !== "HOME" &&
                props.application !== "PARENT" && (
                  <div className="row">
                    <div className="col-12 mt-3">
                      <div className="field">
                        <label htmlFor="idNumber">{t("banner.parentID")}</label>
                        <span className="p-input-icon-right">
                          <InputText
                            id="idNumber"
                            name="idNumber"
                            value={formik.values.idNumber}
                            maxLength={10}
                            onChange={formik.handleChange}
                            className="id-input font-bliss padd-national"
                            placeholder={t("banner.parentNumberPlaceholder")}
                          />
                        </span>
                        {getFormErrorMessage("idNumber")}
                      </div>
                    </div>
                  </div>
                )}
              {props.application !== "TRAVEL" &&
                props.application !== "HOME" &&
                props.application !== "PARENT" && (
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <div className="field d-flex g-2">
                        {props.application === "MOTOR" && isSaudi && (
                          <>
                            <InputSwitch
                              className="p-0"
                              name="hjri"
                              id="hjri"
                              value={formik.values.hjri}
                              error={formik.errors.hjri}
                              onChange={formik.handleChange}
                              checked={formik.values.hjri}
                              style={{ transform: "scale(0.7)" }}
                            />
                            <label>{t("banner.HijriDate")}</label>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="field">
                        <label htmlFor="yearOfBirth">
                          {t("Year_of_Birth")}
                        </label>
                        <Dropdown
                          name="yearOfBirth"
                          id="yearOfBirth"
                          value={formik.values.yearOfBirth}
                          className={
                            classNames({
                              "p-invalid": isFormFieldValid("yearOfBirth"),
                            }) + " font-bliss"
                          }
                          placeholder={t("Select_year")}
                          onChange={formik.handleChange}
                          options={yearsSelectList}
                          optionLabel="label"
                          optionValue="value"
                        />
                        {getFormErrorMessage("yearOfBirth")}
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="field">
                        <label htmlFor="monthOfBirth">
                          {t("banner.monthOfBirth")}
                        </label>
                        <Dropdown
                          name="monthOfBirth"
                          id="monthOfBirth"
                          value={formik.values.monthOfBirth}
                          className={
                            classNames({
                              "p-invalid": isFormFieldValid("monthOfBirth"),
                            }) + " font-bliss"
                          }
                          placeholder={t("banner.monthOfBirthPlaceholder")}
                          onChange={formik.handleChange}
                          options={months}
                          optionLabel="label"
                          optionValue="value"
                        />
                        {getFormErrorMessage("monthOfBirth")}
                      </div>
                    </div>
                    {formik.values.hjri ? (
                      <div className="col-md-12 mt-3">
                        <div className="field">
                          <label htmlFor="dayOfBirth">
                            {t("banner.dayOfBirthPlaceholder")}
                          </label>
                          <Dropdown
                            className="bannerSelectInput font-bliss pl-0"
                            arrowVerticalAlign="bannerArrowAlign"
                            selectArrow={selectArrow}
                            selectControlHeight="22px"
                            selectFontWeight="400"
                            placeholder={t("banner.date_day")}
                            name="dayOfBirth"
                            id="dayOfBirth"
                            options={days}
                            value={formik.values.dayOfBirth}
                            error={formik.errors.dayOfBirth}
                            onChange={formik.handleChange}
                            setFieldValue={formik.setFieldValue}
                          />
                          {getFormErrorMessage("dayOfBirth")}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
            </>
          )}
          {props.sme && (
            <>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <div className="field">
                    <label htmlFor="crNumber">{t("CR_Number")}</label>
                    <span className="p-input-icon-right">
                      <InputText
                        id="crNumber"
                        name="crNumber"
                        value={formik.values.crNumber}
                        onChange={formik.handleChange}
                        maxLength={10}
                        maxlength="10"
                        inputMode="numeric"
                        keyfilter="int"
                        className={classNames({
                          "p-invalid": isFormFieldValid("crNumber"),
                        })}
                        placeholder={t("Enter_CR_number")}
                      />
                    </span>
                    {getFormErrorMessage("crNumber")}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mt-3">
                  <label htmlFor="expiryDate">{t("Expiry_Date")}</label>
                  <div className="field">
                    <span className="p-input-icon-right">
                      <Calendar
                        showIcon
                        id="expiryDate"
                        name="expireDate"
                        value={formik.values.expireDate}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("expiryDate"),
                        })}
                        readOnlyInput
                        placeholder={t("Select_Expiry_Date")}
                      />
                    </span>
                    {getFormErrorMessage("expiryDate")}
                  </div>
                </div>
              </div>
            </>
          )}

          {(props.medmal ||
            props.application === "TRAVEL" ||
            props.application === "HOME" ||
            props.application === "PARENT") && (
            <>
              <div className="row">
                <div className="col-12 mt-3">
                  <div className="field">
                    <label htmlFor="idNumber">{t("National_ID")}</label>
                    <span className="p-input-icon-right">
                      <InputText
                        id="idNumber"
                        name="idNumber"
                        value={formik.values.idNumber}
                        maxLength={10}
                        onChange={formik.handleChange}
                        className="id-input font-bliss padd-national"
                        placeholder={t("banner.iqamaNumberPlaceholder")}
                      />
                    </span>
                    {getFormErrorMessage("idNumber")}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <div className="field">
                    <label htmlFor="yearOfBirth">{t("Year_of_Birth")}</label>
                    <Dropdown
                      name="yearOfBirth"
                      id="yearOfBirth"
                      value={formik.values.yearOfBirth}
                      className={
                        classNames({
                          "p-invalid": isFormFieldValid("yearOfBirth"),
                        }) + " font-bliss"
                      }
                      placeholder={t("Select_year")}
                      onChange={formik.handleChange}
                      options={yearsSelectList}
                      optionLabel="label"
                      optionValue="value"
                    />
                    {getFormErrorMessage("yearOfBirth")}
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="field">
                    <label htmlFor="monthOfBirth">
                      {t("banner.monthOfBirth")}
                    </label>
                    <Dropdown
                      name="monthOfBirth"
                      id="monthOfBirth"
                      value={formik.values.monthOfBirth}
                      className={
                        classNames({
                          "p-invalid": isFormFieldValid("monthOfBirth"),
                        }) + " font-bliss"
                      }
                      placeholder={t("banner.monthOfBirthPlaceholder")}
                      onChange={formik.handleChange}
                      options={months}
                      optionLabel="label"
                      optionValue="value"
                    />
                    {getFormErrorMessage("monthOfBirth")}
                  </div>
                </div>
              </div>
            </>
          )}

          {props.isVisitVisa && (
            <>
              <div className="row">
                <div className="col-12 mt-3">
                  <div className="field">
                    <label htmlFor="idNumber">
                      {t("visitVisaData.visaNumber")}
                    </label>
                    <span className="p-input-icon-right">
                      <InputText
                        id="visaNumber"
                        name="visaNumber"
                        value={formik.values.visaNumber}
                        error={formik.errors.visaNumber}
                        maxLength={10}
                        onChange={formik.handleChange}
                        className={`input-field font-bliss ${classNames({
                          "p-invalid": isFormFieldValid("visaNumber"),
                        })}`}
                        placeholder={t("visitVisaData.visaPlaceHolder")}
                      />
                    </span>
                    {getFormErrorMessage("visaNumber")}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <div className="field d-flex g-2">
                    {isSaudi && (
                      <>
                        <InputSwitch
                          className="p-0"
                          name="hjri"
                          id="hjri"
                          value={formik.values.hjri}
                          error={formik.errors.hjri}
                          onChange={formik.handleChange}
                          checked={formik.values.hjri}
                          style={{ transform: "scale(0.7)" }}
                        />
                        <label>{t("banner.HijriDate")}</label>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="field">
                    <label htmlFor="yearOfBirth">{t("Year_of_Birth")}</label>
                    <Dropdown
                      name="yearOfBirth"
                      id="yearOfBirth"
                      value={formik.values.yearOfBirth}
                      className={
                        classNames({
                          "p-invalid": isFormFieldValid("yearOfBirth"),
                        }) + " font-bliss"
                      }
                      placeholder={t("Select_year")}
                      onChange={formik.handleChange}
                      options={yearsSelectList}
                      optionLabel="label"
                      optionValue="value"
                    />
                    {getFormErrorMessage("yearOfBirth")}
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="field">
                    <label htmlFor="monthOfBirth">
                      {t("banner.monthOfBirth")}
                    </label>
                    <Dropdown
                      name="monthOfBirth"
                      id="monthOfBirth"
                      value={formik.values.monthOfBirth}
                      className={
                        classNames({
                          "p-invalid": isFormFieldValid("monthOfBirth"),
                        }) + " font-bliss"
                      }
                      placeholder={t("banner.monthOfBirthPlaceholder")}
                      onChange={formik.handleChange}
                      options={months}
                      optionLabel="label"
                      optionValue="value"
                    />
                    {getFormErrorMessage("monthOfBirth")}
                  </div>
                </div>
                {formik.values.hjri ? (
                  <div className="col-md-12 mt-3">
                    <div className="field">
                      <label htmlFor="dayOfBirth">
                        {t("banner.dayOfBirthPlaceholder")}
                      </label>
                      <Dropdown
                        className="bannerSelectInput font-bliss pl-0"
                        arrowVerticalAlign="bannerArrowAlign"
                        selectArrow={selectArrow}
                        selectControlHeight="22px"
                        selectFontWeight="400"
                        placeholder={t("banner.date_day")}
                        name="dayOfBirth"
                        id="dayOfBirth"
                        options={days}
                        value={formik.values.dayOfBirth}
                        error={formik.errors.dayOfBirth}
                        onChange={formik.handleChange}
                        setFieldValue={formik.setFieldValue}
                      />
                      {getFormErrorMessage("dayOfBirth")}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              <div className="col-md-12 mt-3">
                <div className="field">
                  <span className="inputTitleOne fs-16 fw-800 pb-1">
                    {t("visitVisaData.expDate")}
                  </span>
                  <br />
                  <Calendar
                    icon
                    minDate={minDateExp}
                    maxDate={maxDateExp}
                    value={formik.values.expiryDate}
                    onChange={formik.handleChange}
                    id="expiryDate"
                    name="expiryDate"
                    placeholder={t("Select_Expiry_Date")}
                    className={`input-field font-bliss ${classNames({
                      "p-invalid": isFormFieldValid("expiryDate"),
                    })}`}
                    dateFormat="yy-mm-dd"
                  />
              </div>
            {getFormErrorMessage("expiryDate")}
          </div>
              </div>
            </>
          )}

          {!props.isVisitVisa && (
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="field bottom-date">
                  <label htmlFor="mobile">{t("Mobile_Number")}</label>
                  {props.medmal ? (
                    <span>
                      <InputText
                        //dir="ltr"
                        id="mobile"
                        name="mobile"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        maxLength={9}
                        className={
                          classNames({
                            values: true,
                            "p-invalid": isFormFieldValid("mobile"),
                          }) + " font-bliss"
                        }
                        placeholder={"5" + "xxxxxxxx"}
                        style={{ fontFamily: "Bliss 2" }}
                        pattern="[0-9]*"
                      />
                    </span>
                  ) : (
                    <span className="p-input-icon-left mobile-number">
                      <i
                        dir="ltr"
                        className="font-bliss"
                        style={{ right: 0, width: "fit-content" }}
                      >
                        +966
                      </i>{" "}
                      <InputText
                        dir="ltr"
                        id="mobile"
                        name="mobile"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        maxLength={9}
                        className={
                          classNames({
                            values: true,
                            "p-invalid": isFormFieldValid("mobile"),
                          }) + " font-bliss"
                        }
                        placeholder="ex: 5xxxxxxxx"
                        style={{ fontFamily: "Bliss 2" }}
                        pattern="[0-9]*"
                      />
                    </span>
                  )}
                </div>
                {getFormErrorMessage("mobile")}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12 mt-2">
              <NormalButton
                type="submit"
                label={t("Buy_Now")}
                className="recentFeedBuyNowBtn"
                onClick={formik.handleSubmit}
              />
            </div>
          </div>
        </form>
        {(props.application === "MOTOR" ||
          props.application === "Medmal" ||
          props.application === "TRAVEL") &&
          !props.isVisitVisa && (
            <div className="w-100 mt-3">
              <div className="d-flex align-items-center justify-content-center">
                <span className="line">-</span>
                <span
                  className={`${
                    selectedLanguage === "en" ? "or-sec" : "or-sec-rtl"
                  }`}
                >
                  {t("networkCoverage.or")}
                </span>
                <span className="line">-</span>
              </div>
              <Button
                label={t("FormRenewBtn")}
                className="p-button-outlined w-100 mt-2"
                onClick={() => {
                  handleRenewClick();
                  trackEvent(props.application + "RenewClicked");
                }}
              />
            </div>
          )}
      </div>
      {showOTP && (
        <OtpPage
          verifyOTP={verifyOTPHandler}
          sendOTP={sendOTPHandler}
          closeOTP={() => setShowOTP(false)}
          mobileNumber={formik.values.mobile}
        />
      )}
    </>
  );
};

export default CommonIndividualsForm;
